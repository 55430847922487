.script-error-events-icon {
  padding-top: 50%;
  padding-bottom: 50%;
  padding-right: 50%;
  /* padding-left: 50%; */
}

.script-error-events-icon.closed {
  color: white;
  background-color: none;
}
.script-error-events-icon.open {
  /* color: black; */
  text-decoration: underline;
}

.script-error-events-icon.active:before {
  position: fixed;
  border-style: solid;
  content: "";
  /* margin-left: 70px; */
  background-color: red;
  border-color: red;
  border-radius: 2em;
  width: 0.5em;
  height: 0.5em;
  left: 10.2em;
}

.no-events-text {
  color: white;
}

.events-menu.closed {
  /* visibility: hidden; */
  display: none;
}
.events-menu.open {
  /* visibility: visible; */
  display: block;
}

.script-error-list ul {
  padding: 0 0;
  /* background-color: #f44336;
  color: white; */
  list-style-type: none;
  text-align: left;
  margin: 0;
  
}

.script-error-list ul :first-child {
  margin-top: 0;
  border-style: none;
}

.script-error-list li {
  padding: 0.1em 0.5em;
  background-color: #8b3832;
  color: white;
  /* margin-top: 0.5em; */
  border-top: 2px;
  border-color: #555;
  border-top-style: solid;
}

.script-error-list div {
  display: block;
}

.script-error-list .collapsed {
  display: none;
}

.script-error-list .caret {
  /* color: #91c6f8c6; */
  font-weight: bold;
  /* margin-left: 0.5em; */
  /* margin-left: auto; 
  margin-right: 0; */
  float: right;
}

.script-error-list {
  position: absolute;

  /* bottom: 10px;
  left: 10px; */
  left: 50px;
  top: 4em;
  /* TODO: below should be universal default Button classes */
  width: auto;
  max-width: 500px;
  min-width: 250px;
  max-height: 400px;
  min-height: 25px;
  margin: 0;
  margin-top: auto;
  overflow: auto;
  scrollbar-width: thin;
  background: #555555;
  /* text-align: left; */
}

.script-error-popup {
  position: absolute;

  /* bottom: 10px;
  left: 10px; */
  left: 1em;
  bottom: 1em;
}

.script-error-popup-notif {
  background-color: red;
  color: white;
  padding: 0.5em;
  border-radius: 0.5em;
  animation: fadeOut 1.5s forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* TODO: Add arrowin */
/* .script-error-list:before {
  z-index: -1;
  position: relative;
  border-style: solid;
  content: "";

  
  top: -45px;
  border-width: 0 10px 15px 10px;
  border-color: transparent transparent blue transparent;
  
} */
